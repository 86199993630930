import { combineReducers } from "redux";

import authReducer from "./auth";
import menuReducer from "./nav";
import agencyReducer from "./agency";
import carsReducer from "./cars";

const allReducers = combineReducers({
  authreducer: authReducer,
  menu: menuReducer,
  userAgency: agencyReducer,
  agencyCars: carsReducer,
});

export default allReducers;
