import {
  TOKEN,
  LOGIN_USER,
} from '../constants/actions';

const INIT_STATE = {
  token: localStorage.getItem('token'),
  user: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case TOKEN:
          return { ...state, token: action.payload };
      case LOGIN_USER:
          return { ...state, user: action.payload };
      default: return { ...state };
  }
}
