/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const TOKEN = "TOKEN";

/* AGENCY */
export const AGENCY = "AGENCY";

/* CARS */
export const CARS = "CARS";

/* MENU */
export const MENU_HIDDEN = "menu-default main-hidden sub-hidden";
export const MENU_EXPOSED = "menu-default sub-hidden";