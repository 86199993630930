import {
  CARS,
} from '../constants/actions';

const INIT_STATE = {
  cars: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case CARS:
          return { ...state, cars: action.payload };
      default: return { ...state };
  }
}
